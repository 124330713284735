import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/ItemCode.css";

const ItemCode = () => {
  const dispatch = useDispatch();
  const { translate } = React.useContext(I18nContext);

  const ItemCodeState = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  return (
    <div
      className="col-xs-12 item-code-container"
      style={{ paddingLeft: "0px" }}
    >
      <p
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "5px 0"
        }}
      >
        <b>{translate("vm.itemTemplate.code")}:</b>
        &nbsp;<span style={{ fontWeight: "400" }}>{ItemCodeState}</span>
      </p>
    </div>
  );
};

export default ItemCode;
