import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  WeiboShareButton,
  TwitterIcon,
  FacebookIcon,
  EmailIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  WeiboIcon,
  WhatsappIcon
} from "react-share";

import classes from "./Styles/ShareButtons.module.css";
import { useLocation } from "@reach/router";

const BUTTON_SIZE = 40;

const ShareButtons = () => {
  const location = useLocation();
  const [productUrl, setProductUrl] = useState(location);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const productInitialStateFromFetch = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const productCode = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  useEffect(() => {
    if (productCode) {
      setImageUrl(
        `https://demob2b2c.avetti.io/preview/store/20180521148/assets/images/galleries/${productCode}.jpg`
      );
    }
  }, [productCode]);

  useEffect(() => {
    if (productInitialStateFromFetch.title != "") {
      setTitle(productInitialStateFromFetch.title);
    }
  }, [productInitialStateFromFetch]);

  return (
    <div className={classes.wrapper}>
      <EmailShareButton
        separator={" "}
        className="share-button"
        url={productUrl}
        subject={`This ${title} Awesome`}
        body={`Check this ${title}, it's really cool and reliable device.`}
      >
        <EmailIcon size={BUTTON_SIZE} round={true} />
      </EmailShareButton>
      <TwitterShareButton
        className="share-button"
        url={productUrl}
        title={`Check this ${title}, it's really cool and reliable device.`}
        hashtags={["Avetti", "DemoStore", "B2B", "ecommerce"]}
      >
        <TwitterIcon size={BUTTON_SIZE} round={true} />
      </TwitterShareButton>
      <FacebookShareButton
        className="share-button"
        url={productUrl}
        quote={`Check this ${title}, it's really cool and reliable device.`}
        hashtags={["Avetti", "DemoStore", "B2B", "ecommerce"]}
      >
        <FacebookIcon size={BUTTON_SIZE} round={true} />
      </FacebookShareButton>
      <LinkedinShareButton
        className="share-button"
        url={productUrl}
        title={`This ${title} Awesome`}
        summary={`Check this ${title}, it's really cool and reliable device.`}
        source={`Avetti Demo Store`}
      >
        <LinkedinIcon size={BUTTON_SIZE} round={true} />
      </LinkedinShareButton>
      <PinterestShareButton
        className="share-button"
        url={productUrl}
        description={`Check this ${title}, it's really cool and reliable device.`}
        media={imageUrl}
      >
        <PinterestIcon size={BUTTON_SIZE} round={true} />
      </PinterestShareButton>

      <LineShareButton
        className="share-button"
        url={productUrl}
        title={`This ${title} Awesome`}
        description={`Check this ${title}, it's really cool and reliable device.`}
      >
        <LineIcon size={BUTTON_SIZE} round={true} />
      </LineShareButton>
      <WhatsappShareButton
        separator={" "}
        className="share-button"
        url={productUrl}
        title={`Check this ${title}, it's really cool and reliable device.`}
      >
        <WhatsappIcon size={BUTTON_SIZE} round={true} />
      </WhatsappShareButton>
    </div>
  );
};

export default ShareButtons;
