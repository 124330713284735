import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { toggleWishListAction } from "../redux/actions/wishListActions";
import { translationKeys } from "./categoryNamesToTranslationKeyMapping";
import { fetchFeaturedMostPopular } from "../redux/actions/mainActions";

import Grid from "@material-ui/core/Grid";

import ItemCard from "../components/AC-CategoryPage/components/ItemCard/ItemCard";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper from "@material-ui/core/MobileStepper";
import { I18nContext } from "../i18n/index";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CategoryFeatured = ({ catNamesState, cid, cat, number, products }) => {
  const { translate } = React.useContext(I18nContext);
  let catNames = catNamesState.map(cat =>
    cat
      .split("-")
      .join("_")
      .toLowerCase()
  );
  let catName = catNames[number];

  let imgName = `${catName}_banner_$NUM.jpg`;

  const images = require.context("../assets/img/main/", true);
  let img1 = images("./" + imgName.replace("$NUM", "1"));
  let img2 = images("./" + imgName.replace("$NUM", "2"));

  const imageSteps = [
    {
      label: "",
      imgPath: img1
    },
    {
      label: "",
      imgPath: img2
    }
  ];
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = imageSteps.length;

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const toggleWish = (e, id, title, desc, currency_sign, image, price, url) => {
    e.preventDefault();
    dispatch(
      toggleWishListAction(
        id,
        title,
        desc,
        currency_sign,
        image,
        price,
        url,
        wishListState
      )
    );
  };

  return (
    <div>
      <div className="featured-items-container">
        <h3
          className="featured-items-title"
          dangerouslySetInnerHTML={{
            __html: `${translate(`MostPopular${translationKeys[catName]}`)}`
          }}
        />
      </div>
      <div className="featured-category-container">
        <Grid container className="item-card-container">
          <Grid item className="item-card-item" xs={12} sm={6} md={6} lg={3}>
            <div
              style={{
                maxHeight: "435px",
                overflow: "hidden"
              }}
            >
              <AutoPlaySwipeableViews
                axis={"x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {imageSteps.map((step, index) => (
                  <div
                    key={index}
                    className="category-featured-swipeable-images-wrapper"
                  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <img
                        className="category-featured-swipeable-image"
                        style={{
                          width: "100%",
                          height: "auto"
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                className="category-featured-mobile-stepper"
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                nextButton=""
                backButton=""
                style={{
                  transform: "translateY(-110px)",
                  position: "relative",
                  background: "transparent",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%"
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            id="category-featured"
            className="item-card-item"
            xs={12}
            sm={12}
            md={12}
            lg={9}
          >
            <Grid container className="item-card-container">
              {products.map(item => (
                <Grid
                  item
                  className="item-card-item"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={4}
                  key={item.id}
                >
                  <ItemCard
                    itemCard={item}
                    key={item.id}
                    toggleWish={toggleWish}
                    wishListState={wishListState}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CategoryFeatured;
