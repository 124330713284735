import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  getSupplierInfo,
  fetchingProductRequestSaga,
  fetchingProductPriceInventory,
  changeTitleAndLongDesc
} from "../../redux/actions/productAction";
import Footer from "../AC-Footer/Footer";
import "../../assets/css/product-sl.css";
import "./Styles/ProductPage.css";
import ImageCarousel from "./Components/ImageCarousel/ImageCarousel";
import OtherInfoTab from "./Components/OtherInfoTab/OtherInfoTab";
import VendorList from "./Components/Vendors/VendorList";
import ReviewBar from "./Components/Review/ReviewBar";
import NoItem from "../AC-CategoryPage/components/NoItems/NoItems";
import WishListBar from "./Components/WishList/WishListBar";
import Attributes from "../ACG-ProductPage/Components/Attributes/BDAttributes";
import AddToCartBox from "./Components/AddToCart/AddToCartBox";
import AddedToCartModal from "./Components/AddToCart/Components/AddedToCartModal";
import RecentlyViewedItems from "../../components/AC-RecentlyViewedItems/RecentlyViewedItems";
import ItemCode from "./Components/ItemCode/ItemCode";
import HelmetSeo from "../../shared/components/AC-Helmet/HelmetSeo";
import CheckBoxAddons from "./Components/CheckBoxAddons/CheckBoxAddons";
import Reviews from "./Components/Review/components/Reviews";
import CartValidationErrors from "./Components/CartValidationErrors/CartValidationErrors";
import OutOfStockError from "../AC-ProductPage/Components/CartValidationErrors/OutOfStockError";
import ConfirmDeliveryOptions from "./Components/AddToCart/Components/ConfirmDeliveryOptions/ConfirmDeliveryOptions";

import htmldecoder, { htmlDecode } from "../../functions/htmldecoder";

import {
  addRecentItems,
  addRecentViewItemsAction
} from "../../redux/actions/recentlyViewedItemsActions.js";
import Accessories from "./Components/Accessories/Accessories";
import AccessoryModal from "./Components/Accessories/components/AccessoryModal";
import { I18nContext } from "../../i18n/index";
import ShareButtons from "./Components/ShareButtons/ShareButtons";
import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import Marketplaces from "./Components/Marketplaces/Marketplaces";
import Loading from "../AC-Loading/Loading";
import { useLocation, navigate } from "@reach/router";
import { PROJECT_LINK } from "../../project-config";
import { setGoBackToCategoryFromProductPage } from "../../redux/actions/categoryActions";

const ProductPageRouter = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  if (!location.pathname.includes("ain")) {
    navigate("/");
  }

  useEffect(() => {
    let productUrl = location.pathname;
    dispatch(setGoBackToCategoryFromProductPage(true));
    if (productUrl.charAt(productUrl.length - 1) === "/") {
      productUrl = productUrl.substring(0, productUrl.length - 1);
    }
    fetch(`${PROJECT_LINK}/product${productUrl}?tpt=json_en`)
      .then(res => res.json())
      .then(json => {
        console.log(
          "productpage router fetch",
          json,
          json[0].id,
          `${PROJECT_LINK}/product${productUrl}?tpt=json_en`
        );
        setTitleState(json[0].title);

        let tempJson = json && json[0];
        tempJson.productLink = productUrl;

        dispatch(changeTitleAndLongDesc(tempJson));

        dispatch(fetchingProductRequestSaga(json[0].id));

        dispatch(fetchingProductPriceInventory(json[0].id));
      })
      .catch(err => {
        console.error("err productpage router fetch item", err);
        navigate("/");
      });

    return () => {
      dispatch(setGoBackToCategoryFromProductPage(false));
    };
  }, []);

  const { translate, currency, priceConvert } = React.useContext(I18nContext);
  const reviewsContainerRef = useRef();

  const [titleState, setTitleState] = useState("");
  const [enquiryModalState, setEnquiryModalState] = useState(false);
  const [reviewsWithImages, setReviewsWithImages] = useState([]);

  const [supplierName, setSupplierName] = React.useState("");
  const [supplierCity, setSupplierCity] = React.useState("");

  const [starFilter, setStarFilter] = useState([]);
  const [avgRating, setAvgRating] = useState({
    avgRating: 0,
    countOfEachStar: {},
    totalValue: 0
  });

  const productInitialState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const itemDetailsTitleState = useSelector(
    state => state.productReducer.itemDetail.title,
    shallowEqual
  );

  const itemDetailIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const productInitialStateFromFetch = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const noProductState = useSelector(
    state => state.productReducer.noproduct,
    shallowEqual
  );
  const supplierInfoReducer = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const recentViewItemsState = useSelector(
    state => state.recentlyViewedItemsReducer.recentlyViewedItemsList,
    shallowEqual
  );

  React.useEffect(() => {
    if (supplierInfoReducer.length > 0) {
      if (supplierInfoReducer[0].distributorOrder.length > 0) {
        setSupplierName(supplierInfoReducer[0].distributorOrder[0].name);
        setSupplierCity(supplierInfoReducer[0].distributorOrder[0].city);
      }
    }
  }, [supplierInfoReducer]);

  useEffect(() => {
    console.info("RENDER++", itemDetailState.code);
    if (typeof window !== undefined && itemDetailState.code != "") {
      dispatch(getSupplierInfo(itemDetailState.itemid));
      window.scrollTo(0, 0);
    }
  }, [itemDetailState.code]);

  useEffect(() => {
    if (productInitialStateFromFetch.title != "") {
      /*  if (productInitialState != "") {
        setTitleState(itemDetailsTitleState);
      } else { */
      setTitleState(productInitialStateFromFetch.title);
      /*       } */
    }
  }, [productInitialStateFromFetch /* , itemDetailsTitleState */]);

  // recently viewed items related TEST
  useEffect(() => {
    if (
      itemDetailIdState &&
      priceState &&
      priceState.prices &&
      priceState.prices.length > 0
    ) {
      console.info("BURAYABAKARLAR2", itemDetailIdState, priceState);

      let itemDetailsTemp = { ...itemDetailState };
      let {
        itemId: id,
        title,
        currency_sign = "$",
        image,
        image3: itemLargeImage,
        url = location.pathname.replace("/preview", "")
      } = itemDetailsTemp;
      title = title.length > 40 ? title.substr(0, 40).concat(" ...") : title;
      let tempPrice = priceState.prices[0].listprice.toFixed(2);
      tempPrice = tempPrice.split(".");
      let price = {
        type: "default",
        value: { decimal: tempPrice[1], integer: tempPrice[0] }
      };
      itemLargeImage = itemLargeImage.substring(
        itemLargeImage.indexOf("/store"),
        itemLargeImage.length
      );

      let previouslyAddedItem = recentViewItemsState.filter(
        item => item.id == id
      )[0];

      console.info("BURAYABAKARLAR3", previouslyAddedItem);

      if (previouslyAddedItem) {
        if (previouslyAddedItem.url != url && previouslyAddedItem.id == id) {
          return;
        }
      }

      if (recentViewItemsState.length >= 10) {
        let tempRecentViewItemsState = recentViewItemsState.filter((v, i) => {
          if (i == 0) {
            return false;
          }
          return true;
        });
        console.info("qwerty", tempRecentViewItemsState, recentViewItemsState);
        dispatch(
          addRecentItems([
            ...tempRecentViewItemsState.filter(item => item.id != id),
            { id, title, currency_sign, image, itemLargeImage, price, url }
          ])
        );
      } else {
        dispatch(
          addRecentViewItemsAction(
            id,
            title,
            currency_sign,
            image,
            itemLargeImage,
            price,
            url,
            recentViewItemsState
          )
        );
      }
    }
  }, [itemDetailIdState, priceState]);

  const handleEnquiryModalOpenClicked = () => {
    setEnquiryModalState(true);
  };

  if (!noProductState) {
    console.info("RENDER---");
    return (
      <React.Fragment>
        <div id="bd" className="item">
          <Breadcrumb back={true} />
          <div className="col s12">
            <div style={{ marginTop: "5px" }}>
              <div className="item-main">
                <div id="mainGridDiv" className="item-top-row row">
                  <div id="leftGrid">
                    <ImageCarousel />
                    <ShareButtons />
                  </div>
                  <div id="rightGrid">
                    <div id="titleGrid">
                      <div
                        id="js-item-title-267578"
                        className="regularTitle title itemTitle itemTitleMobile"
                        dangerouslySetInnerHTML={{
                          __html: htmlDecode(titleState)
                        }}
                      ></div>
                      <ReviewBar
                        avgRating={avgRating}
                        starFilter={starFilter}
                        setStarFilter={setStarFilter}
                        reviewsContainerRef={reviewsContainerRef}
                      />
                      <WishListBar />
                      <ItemCode />

                      <div className="col-xs-12 product-page-seller-info">
                        <div className="inStock-container">
                          {priceState &&
                          Object.keys(priceState).includes("invs") &&
                          priceState.invs &&
                          priceState.invs.length > 0 &&
                          Object.keys(priceState.invs[0]).includes(
                            "instock"
                          ) ? (
                            <div className="inStock-wrapper">
                              <label>{translate("js.item.stock")}:</label>
                              &nbsp;
                              <span style={{ fontWeight: "400" }}>
                                {priceState.invs[0].instock}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <p>
                          <strong>{translate("js.item.available")}: </strong>
                          <span
                            id="buyBoxDistName"
                            style={{ fontWeight: "400" }}
                          >
                            {supplierName}
                          </span>
                        </p>
                        <p>
                          <strong>{translate("js.item.location")}: </strong>
                          <span
                            id="buyBoxDistLoc"
                            style={{ fontWeight: "400" }}
                          >
                            {supplierCity}
                          </span>
                        </p>

                        <div className="product-page-seller-rating">
                          <strong>
                            {translate("js.item.sellerrating")}:&nbsp;
                          </strong>
                          <div id="buyBoxDistRate">
                            <div className="dist-item-rating">
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <div
                                style={{ display: "none" }}
                                className="distReviewCount"
                              >
                                0 {translate("js.item.reviews")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Attributes />

                      {/*                      <Marketplaces />*/}
                    </div>
                    <CheckBoxAddons />
                    <AddToCartBox
                      handleEnquiryModalOpenClicked={
                        handleEnquiryModalOpenClicked
                      }
                    />
                  </div>
                  <ConfirmDeliveryOptions />
                  <CartValidationErrors />
                  <OutOfStockError />
                  <AddedToCartModal />
                </div>
              </div>
            </div>
          </div>
          {/* <Accessories />
          <VendorList
            handleEnquiryModalOpenClicked={handleEnquiryModalOpenClicked}
          /> */}
          <OtherInfoTab />
          <div className="clearfix"></div>
          <Reviews
            avgRating={avgRating}
            setAvgRating={setAvgRating}
            starFilter={starFilter}
            setStarFilter={setStarFilter}
            reviewsContainerRef={reviewsContainerRef}
            reviewsWithImages={reviewsWithImages}
            setReviewsWithImages={setReviewsWithImages}
          />
          {/* <RecentlyViewedItems /> */}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div id="bd" className="item">
          <div className="col s12">
            <Loading />
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default ProductPageRouter;
