import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

const Slider = () => {
  const data = useStaticQuery(graphql`
    query {
      img2: file(relativePath: { eq: "slider/ab.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  return (
    <>
      <div id="homeBanner">
        <Img
          className="mobile-home-banner-image"
          sizes={{ ...data.img2.childImageSharp.fluid, aspectRatio: 360 / 406 }}
        />

        <div className="mobileSliderBtns">
          <p>Welcome to the Market</p>

          {navCatsState &&
            navCatsState.childs.map(child => {
              const { URL, description, name } = child;
              if (name !== "Sellers")
                return (
                  <Link key={name} to={URL.replace("shop", "")}>
                    {description}
                  </Link>
                );
            })}
          <div className="sliderSellerBtn">
            {navCatsState &&
              navCatsState.childs.map(child => {
                const { URL, description, name } = child;
                if (name === "Sellers")
                  return (
                    <Link key={name} to={URL.replace("shop", "")}>
                      {description}
                    </Link>
                  );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
