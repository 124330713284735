import React, { useContext } from "react";
import { I18nContext } from "../i18n";
import Async from "react-code-splitting";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";





const SearchBar = () => <Async load={import("../header/SearchHelper")} />;
const ServicesBox = () => {
  const { translate, langCode } = useContext(I18nContext);
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 1600 },
  //     items: 5
  //   },
  //   desktop: {
  //     breakpoint: { max: 1600, min: 1360 },
  //     items: 5
  //   },
  //   mdDesktop: {
  //     breakpoint: { max: 1360, min: 1024 },
  //     items: 4
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 768 },
  //     items: 3
  //   },
  //   mobile: {
  //     breakpoint: { max: 768, min: 520 },
  //     items: 2
  //   },
  //   xsMobile: {
  //     breakpoint: { max: 520, min: 0 },
  //     items: 1
  //   }
  // };
  return (


    <div className="caro">
      


      


      

      {/* <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile", "xsMobile"]}
        // deviceType={this.props.deviceType}
        itemClass="carousel-item-padding-40-px"
        dotListclassName="custom-dot-list-style"
      >

        <div
          className="owl-item "
        >
          
            <div className="home-item_Home">
              <div className="item-card-image">
                <figure className="item-card-figure">
                  <span className="figure"><img src="https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/AINTGEZHP0.jpg" /></span>
                </figure>
              </div>
              <div className="item-card-regulars">
                <div className="product-titles">Black Logitech G203 Prodigy Wired Mouse</div>
              </div>
            </div>
        </div>
        <div
          className="owl-item "
        >
          <div className="home-item_Home">
              <div className="item-card-image">
                <figure className="item-card-figure">
                  <span className="figure"><img src="https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/AIN9BMKK83.jpg" /></span>
                </figure>
              </div>
              <div className="item-card-regulars">
                <div className="product-titles">Black Logitech G203 Prodigy Wired Mouse</div>
              </div>
            </div>
            </div>
        <div
          className="owl-item "
        >
          <div className="home-item_Home">
              <div className="item-card-image">
                <figure className="item-card-figure">
                  <span className="figure"><img src="https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/AIN2V75S23.jpg" /></span>
                </figure>
              </div>
              <div className="item-card-regulars">
                <div className="product-titles">Samsung SCX-4200 multifunctional Laser 1...</div>
              </div>
            </div>
            </div>
        <div
          className="owl-item "
        >
          <div className="home-item_Home">
              <div className="item-card-image">
                <figure className="item-card-figure">
                  <span className="figure"><img src="https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/AINGVPFB92.jpg" /></span>
                </figure>
              </div>
              <div className="item-card-regulars">
                <div className="product-titles">Epson Stylus Photo RX620</div>
              </div>
            </div>
            </div>
        <div
          className="owl-item "
        >
          <div className="home-item_Home">
              <div className="item-card-image">
                <figure className="item-card-figure">
                  <span className="figure"><img src="https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/AINPYOJOV0.jpg" /></span>
                </figure>
              </div>
              <div className="item-card-regulars">
                <div className="product-titles">Sony VAIO VGN-CS31S R notebook Red 35 8 ...</div>
              </div>
            </div>
            </div>
        <div
          className="owl-item "
        >
          <div className="home-item_Home">
              <div className="item-card-image">
                <figure className="item-card-figure">
                  <span className="figure"><img src="https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/AINJT5J3E2.jpg" /></span>
                </figure>
              </div>
              <div className="item-card-regulars">
                <div className="product-titles">Samsung Pixon 12 M8910 7 87 cm (3 1"...</div>
              </div>
            </div>
            </div>
        
      </Carousel> */}
      {/* <ul>
        <li className="avetti-icon-box icon_position-left">
          <div className="mf-icon box-icon">
            <i
              className="material-icons"
              style={langCode === "ar" ? { transform: "scaleX(-1)" } : {}}
            >
              local_shipping
            </i>
          </div>
          <div className="box-wrapper">
            <span className="box-title">{translate("Freedelivery")}</span>
            <div className="desc">{translate("Forallodersover99")}</div>
          </div>
        </li>

        <li className="avetti-icon-box icon_position-left">
          <div className="mf-icon box-icon">
            <i className="material-icons">replay_30</i>
          </div>
          <div className="box-wrapper">
            <span className="box-title">{translate("90DaysReturn")}</span>
            <div className="desc">{translate("Ifgoodshaveproblems")}</div>
          </div>
        </li>

        <li className="avetti-icon-box icon_position-left">
          <div className="mf-icon box-icon">
            <i className="material-icons">policy</i>
          </div>
          <div className="box-wrapper">
            <span className="box-title">{translate("SecurePayment")}</span>
            <div className="desc">{translate("100SecurePayment")}</div>
          </div>
        </li>

        <li className="avetti-icon-box icon_position-left">
          <div className="mf-icon box-icon">
            <i className="material-icons">headset_mic</i>
          </div>
          <div className="box-wrapper">
            <span className="box-title">{translate("247support")}</span>
            <div className="desc">{translate("Dedicatedsupport")}</div>
          </div>
        </li>
      </ul> */}
    </div>
  );
};

export default ServicesBox;
