import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import StoreCard from "../components/AC-Stores/components/StoreCard";
import Grid from "@material-ui/core/Grid";
import { graphql, useStaticQuery } from "gatsby";

function FeaturedSellers() {
  const data = useStaticQuery(graphql`
    query {
      allAvettiCategory(filter: { name: { eq: "Sellers" } }) {
        nodes {
          itemsFirstPage {
            id
            title
            code
            desc
            currency_sign
            image
            itemLargeImage
            price {
              value {
                integer
                decimal
              }
            }
            url
            properties {
              lng
              lat
              Website
              UPC
              Sys_Package_Price_Enabled
              Sys_Num_Images
              Supplier_Item_Code
              ProvinceAbv
              Province
              Phone
              Per_Unit
              LineAddress1
              Email
              Created_By_Supplier
              Country
              City
              Brand
              createtime
            }
          }
        }
      }
    }
  `);

  const storesState = useSelector(
    state => state.storeReducer.stores,
    shallowEqual
  );

  const storeItemsAreLoading = useSelector(
    state => state.storeReducer.loading,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1600 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1600, min: 1360 },
      items: 5
    },
    mdDesktop: {
      breakpoint: { max: 1360, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 768, min: 520 },
      items: 2
    },
    xsMobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1
    }
  };

  const renderPlaceholderCards = () => {
    return (
      <Carousel
        swipeable={false}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        autoPlay={isMobileState ? true : false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile", "xsMobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {Array(6)
          .fill(0, 0, 6)
          .map((v, i) => (
            <Grid key={i} item className="item-card-item" xs={12}>
              <div
                className="placeholder-item-card-wrapper"
                style={{ boxShadow: "0px 0px 1px 0px #c8c8c8" }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    height: "400px"
                  }}
                ></div>
                <div>
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "40px"
                    }}
                  ></div>
                </div>
              </div>
            </Grid>
          ))}
      </Carousel>
    );
  };

  return (
    <div>
      <div className="header-container">
        <h4 className="local">Local Businesses&nbsp; - Global</h4>

        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={isMobileState ? true : false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile", "xsMobile"]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {data &&
            data.allAvettiCategory.nodes[0].itemsFirstPage.map(item => (
              <div key={item.id} className="owl-item item-card-itemFeatured">
                <StoreCard key={item.id} itemCard={item} />
              </div>
            ))}
        </Carousel>
      </div>
    </div>
  );
}

export default FeaturedSellers;
