import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import "./FeaturedPromotions.css";

import Image1 from "../assets/img/slider/1.jpg";
import Image2 from "../assets/img/slider/2.jpg";
import Image3 from "../assets/img/slider/3.jpg";
import Image4 from "../assets/img/slider/4.jpg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const FeaturedPromotions = () => {
  const data = useStaticQuery(graphql`
    query {
      Image1: file(relativePath: { eq: "orderOnline.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image2: file(relativePath: { eq: "slider/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image3: file(relativePath: { eq: "slider/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image4: file(relativePath: { eq: "slider/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  return (
    <div
      style={{
        marginTop: "4%"
      }}
    >
      <div className="newsletter-wrapper">


        <div className="mailchimp-wrapper">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            // autoPlay
            autoPlaySpeed={4000}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            // draggable
            focusOnSelect={false}
            // infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 1
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 1
              }
            }}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <Img fluid={data.Image1.childImageSharp.fluid} />
            {/* <Img fluid={data.Image2.childImageSharp.fluid} />
            <Img fluid={data.Image3.childImageSharp.fluid} />
            <Img fluid={data.Image4.childImageSharp.fluid} /> */}
          </Carousel>
        </div>
        <div className="newsletter-content">
          <h3>
            The Barrie Farmers’ Market awaits you!
          </h3>
          <p>These days, more and more people are making the healthy decision to buy locally grown fruit and vegetables, meats, dairy, eggs, baked goods, and handmade products from farmers’ markets. </p>
          <p>A wonderful connection is made when you talk to the people who take pride in producing your food!
          Once you visit our market, you’ll know why the Barrie Farmers’ Market is such an extraordinary shopping and social experience.
          </p>
          <h3>Order Online Now !</h3>
          <p>For customers who want to guarantee their favourite products will be waiting for them at the market, or for those customers who want to minimize contact during the COVID-19 public health response, you can place orders in advance through the Barrie Farmers’ Market </p>
          {/* <span className="cafe-button">
            <Link to={"/seller-registration"}>Seller Sign Up</Link>
          </span> */}
        </div>
      </div>

      {/* <Link
            to={
              lang !== "en"
                ? PREVIEW + `/${lang}` + "/shop/laptops"
                : PREVIEW + "/shop/laptops"
            }
            onClick={() =>
              handleCategoryChange(
                "432307",
                "Laptops",
                ["Laptops", "432307"],
                "Laptops"
              )
            }
          ></Link>
            
            
              <h3 className="cafe-banner-title">
              >
              
                
                
              
          </Link> */}
    </div>
  );
};

export default FeaturedPromotions;
